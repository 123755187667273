function bookings_step2() {
  var self = this;

  this.init = function () {
    if ($('#datepicker').length) {
      $('#datepicker').datepicker({
        minDate: 0,
        defaultDate: null,
        dateFormat: 'yy-mm-dd',
        onSelect: function (date) {
          $(".select-time").removeClass("d-none");

          fetch('ajaxActions.cgi?action=bookingTimes&date=' + date + '&space=' + $('input[name="space"]:checked').val() + '&s2=' + $("#s2").val() + '&s4=' + $("#s4").val() + 'ts=' + new Date().getTime())
          .then(response => response.text())
          .then(function(data) {
            var $bookingTime = $("#bookingTime");
            $bookingTime.empty();
            $bookingTime.append(data);
          })
          .catch((error) => { console.error('Error:', error);});

        }
      });
    }
    this.validateApplication();
    this.bindEvents();

  }

  this.validateApplication = function () {

    $("#bookings_step2").validate({

      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if (element.attr("type") == "checkbox") {
          error.prependTo(element.parents("div.form-group"));
        } else {
          error.insertBefore(element);
        }
      },

      rules: {

      },

      messages: {
        email: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your email address',
          email: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter an email address in the correct format, like name@example.com'
        }
      },

      submitHandler: function(form) {

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#bookings_step2').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            form.submit();
          });
        });

      }

    });

  }

  this.bindEvents = function () {

    $(".room-desc-mobile").on('click',function() {
      $(".modal-title").html($(this).data('value'));
      $(".modal-body").html($(this).parent().next().html());
    });


    /* Show the date selector once a room has been chosen */
    $(".room-selector").on('click',function() {
      $(".select-date").removeClass("d-none");
      $(".select-time").addClass("d-none");

      /* Remove the next button if they have selected a different room */
      $('#toStep3').addClass("d-none");

      $('html, body').animate({
        scrollTop: $(".select-date").offset().top
      }, 2000);

    });

    /* Show the Extend booking button on a selected time */
    $(document).on("click", 'input[name="selected_time"]', function(event) {
      /* Reset all states if a user selects a different time */
      $('.extend-booking').addClass("d-none");
      $('.progress-link').addClass("d-none");
      $('.bookingTimeRow').css({"backgroundColor":"white"});
      $('.extend-available').text('Extended booking available');
      $('#extend').val(0);
      $('input[name="selected_time"]').css({"opacity":"1"});

      /* Show the next button */
      $('#toStep3').removeClass("d-none");

      /* Show the extend booking button if applicable */
      $(this).parent().parent().siblings('.extend-booking').removeClass("d-none");

      /* Show the progress link to easily navigate them to the next button. Very useful for mobile where the next button is off the page */
      $(this).parent().siblings('.progress-link').removeClass("d-none");
    });

    /* Highlight which times will be booked */
    $(document).on("click", '.extend-booking', function(event) { 
      $(this).parent().css({"backgroundColor":"lightgrey"});
      $(this).parent().next().css({"backgroundColor":"lightgrey"});

      /* Hide the radio for the extended slot to not confuse users */
      $(this).parent().next().find('input[name="selected_time"]').css({"opacity":"0"});

      $('.extend-booking').addClass("d-none");
      $(this).parent().find('.extend-available').html('Booking extended.');

      /* The selected time gets extended & sent to the server */
      $('#extend').val(1);
      
    });

  }

}

module.exports = bookings_step2;
