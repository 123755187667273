function wheelchair_step2() {
  var self = this;

  this.init = function () {

    this.validateApplication();
    this.bindEvents();

  }

  this.validateApplication = function () {

    jQuery.validator.addMethod("noHTMLtags", function(value, element){
      if(/<\/?[^>]+(>|$)/g.test(value)){
          return false;
      } else {
          return true;
      }
    }, '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> HTML tags are not allowed.');


    $("#wheelchair_step2").validate({

      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if (element.attr("type") == "checkbox") {
          error.prependTo(element.parents("div.form-group"));
        } else {
          error.insertBefore(element);
        }
      },

      rules: {
        first_name: {
          noHTMLtags: true
        },
        last_name: {
          noHTMLtags: true
        },
        phone: {
          noHTMLtags: true
        }
      },

      messages: {
        first_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your given name'
        },
        last_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your family name'
        },
        email: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your email address',
          email: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter an email address in the correct format, like name@example.com'
        },
        phone: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your phone number',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Phone must be 8 characters or more',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Phone number must only include digits 0 to 9, hyphens, plus, spaces and brackets'
        }
      },

      submitHandler: function(form) {

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#wheelchair_step2').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            form.submit();
          });
        });

      }

    });

  }

  this.bindEvents = function () {
  
  }

}

module.exports = wheelchair_step2;
