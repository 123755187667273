function Step3() {
  this.init = function () {

    this.validateStep3();
    this.bindEvents();

  }

  this.validateStep3 = function () {

    $.validator.addMethod('CCExp', function(value, element, params) {
      var minMonth = new Date().getMonth() + 1;
      var minYear = parseInt(new Date().getFullYear().toString().substr(-2));
      var month = parseInt($(params.month).val(), 10);
      var year = parseInt($(params.year).val(), 10);

      return (year > minYear || (year === minYear && month >= minMonth));
    }, '<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span><span class="sr-only">Error:</span> Expiry must be in the future');

    $("#step3").validate({

      ignore: [],
      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if (element.attr("type") == "checkbox") {
          error.prependTo(element.parents("div.form-group"));
        } else if (element.hasClass("cc_expiry_error_placement")) {
          error.prependTo(element.parents("div.form-group"));
        } else {
          error.insertBefore(element);
        }
      },

      rules: {
        cardNumber: {
          normalizer: function(value) {
            return this.value.replace(/ /g,'');
          },
          creditcard: true
        },
        cc_expiry_month: {
          required: true,
          minlength: 2,
        },
        cc_expiry_year: {
          required: true,
          minlength: 2,
          CCExp: {
            month: '#cc_expiry_month',
            year: '#cc_expiry_year'
          }
        }
      },

      messages: {
        cardNumber: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the long number on the front of the card',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Card number must be 13 characters or more',
          creditcard: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a valid credit card number'
        },
        cc_expiry_month: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a month, like 09',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a month, like 09',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a month, like 09'
        },
        cc_expiry_year: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a year, like 21',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a year, like 21',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter a year, like 21'
        },
        cardName: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the full name on front of the card'
        },
        cardSecurityCode: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the card security code',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Card security code must be 3 or 4 characters',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Card security code must be a number'
        },
        agree_tc: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Please accept the terms and conditions to continue'
        }
      },

      submitHandler: function(form) {

        /* Make sure the form can't be double clicked & provide a spinner so the user knows the form is submitting */
        $("#step3-submit-div").remove();
        $("#loading-spinner").removeClass('d-none');

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#step3').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            form.submit();
          });
        });

      }

    });

  }

  this.bindEvents = function () {

    /* Make sure the CVC hint applies to the right type of card */
    $("#cardNumber").change(function() {
      if ($(this).val().match(/^3[47]\d{13}/)) {
        $("#cardSecurityCode").attr('maxlength','4');
        $("#cardSecurityCode").attr('minlength','4');
      } else {
        $("#cardSecurityCode").attr('maxlength','3');
        $("#cardSecurityCode").attr('minlength','3');
      }
    });

    $("input[name='payment_frequency']").change(function(){

      var payment_frequency = $(this).val();

      $.ajax({
        method: "POST",
        url: "/ajaxFunctions.cgi",
        data: { 
          id: $("#id").val(),
          s: $("#s").val(),
          tier: $("#tier").val(),
          payment_frequency: payment_frequency,
          action: 'checkout_total' }
      }) 
        .done(function( data ) {
          $("#membership_label").text(data.amount_dollars.membership_label);
          $("#membership_cost").text('$' + data.amount_dollars.membership);
          $(".checkout_total").text('$' + data.amount_dollars.checkout_total);
        })
        .fail(function(x) {
          console.log(x);
      });

    });

  }

}

module.exports = Step3;
