import $ from "jquery";

import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';

var popper = require('popper.js');
var bootstrapjs = require('bootstrap');
var validation = require('jquery-validation');

var step1 = require('./components/step1');
var step2 = require('./components/step2');
var step_create_password = require('./components/create_password');
var step3 = require('./components/step3');
var membership_complete = require('./components/membership_complete');
var bookings_step1 = require('./components/bookings_step1');
var bookings_step2 = require('./components/bookings_step2');
var bookings_step3 = require('./components/bookings_step3');
var bookings_induction = require('./components/bookings_induction');
var verify_step1 = require('./components/verify_step1');
var verify_step2 = require('./components/verify_step2');
var retrieval_step1 = require('./components/retrieval_step1');
var preferences_step1 = require('./components/preferences_step1');
var gift_step1 = require('./components/gift_step1');
var activate_step1 = require('./components/activate_step1');
var payments = require('./components/payments');
var wheelchair_step1 = require('./components/wheelchair_step1');
var wheelchair_step2 = require('./components/wheelchair_step2');

window.jQuery = $;
window.$ = $;

require("jquery-validation/dist/additional-methods.js");

$(document).ready(function() {

  var s1 = new step1();
  s1.init();
  var s2 = new step2();
  s2.init();
  var create_password = new step_create_password();
  create_password.init();
  var s3 = new step3();
  s3.init();
  var mc = new membership_complete();
  mc.init();

  var bookings_s1 = new bookings_step1();
  bookings_s1.init();
  var bookings_s2 = new bookings_step2();
  bookings_s2.init();
  var bookings_s3 = new bookings_step3();
  bookings_s3.init();
  var bookings_i1 = new bookings_induction();
  bookings_i1.init();


  var verify_s1 = new verify_step1();
  verify_s1.init();
  var verify_s2 = new verify_step2();
  verify_s2.init();

  var retrieval_s1 = new retrieval_step1();
  retrieval_s1.init();

  var preferences_s1 = new preferences_step1();
  preferences_s1.init();

  var gift_s1 = new gift_step1();
  gift_s1.init();

  var activate_s1 = new activate_step1();
  activate_s1.init();

  var payment_step = new payments();
  payment_step.init();

  var wheelchair_s1 = new wheelchair_step1();
  wheelchair_s1.init();
  var wheelchair_s2 = new wheelchair_step2();
  wheelchair_s2.init();

  $('[data-toggle="tooltip"]').tooltip()

});
