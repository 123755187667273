function verify_step2() {
  this.init = function () {
    this.validateStep2();
    this.bindEvents();

  }

  this.validateStep2 = function () {

    $("#verify-step2").validate({

      ignore: [],
      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        error.insertBefore(element);
      },

      rules: {
        offers: {
          required: true
        }
      },

      messages: {
        offers: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Please select at least one offer'
        }
      },

      submitHandler: function(form) {
        form.submit();
      }

    });

  }

  this.bindEvents = function () {

  }

}

module.exports = verify_step2;
