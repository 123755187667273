function gift_step1() {
  var self = this;

  this.init = function () {

    this.validateApplication();
    this.bindEvents();

    if ($('form input[type=radio][name=delivered_to]').length) {
      if ($('form input[type=radio][name=delivered_to]:checked').val() === 'recipient') {
        $("#recipient_custom_msg").show();
      } else {
        $("#recipient_custom_msg").hide();
      }
    }

  }

  this.validateApplication = function () {

    jQuery.validator.addMethod("noHTMLtags", function(value, element){
      if(/<\/?[^>]+(>|$)/g.test(value)){
          console.log(element);

          return false;
      } else {
          return true;
      }
    }, '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> HTML tags are not allowed.');

    $("#gift_step1").validate({

      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if ((element.attr("type") == "checkbox") || (element.attr("type") == "radio")) {
          error.prependTo(element.parents(".radio-end-of-fieldset"));
        } else {
          error.insertBefore(element);
        }
      },

      rules: {
        first_name: {
          noHTMLtags: true
        },
        last_name: {
          noHTMLtags: true
        },
        recipient_first_name: {
          required: true,
          noHTMLtags: true
        },
        recipient_last_name: {
          required: true,
          noHTMLtags: true
        },
        recipient_email: {
          required: true,
        },
        recipient_msg: {
          noHTMLtags: true
        }
      },

      messages: {
        tier: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Select your membership type'
        },
        first_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your given name'
        },
        last_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your family name'
        },
        email: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your email address',
          email: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter an email address in the correct format, like name@example.com'
        },
        recipient_first_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the recipients given name'
        },
        recipient_last_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the recipients family name'
        },
        recipient_email: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the recipients email address',
          email: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter an email address in the correct format, like name@example.com'
        }

      },

      submitHandler: function(form) {

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#gift_step1').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            form.submit();
          });
        });

      }

    });

  }

  this.toggle = function(className, obj) {
    var $input = $(obj);
    if ($input.prop('checked')) $(className).show();
    else $(className).hide();
  }

  this.bindEvents = function () {

    /* Show hide the recipient block */
    $("form input[type=radio][name=delivered_to]").on('click',function() {
      //self.toggle('#recipient_custom_msg', this);
      console.log($(this));
      console.log($(this).val());
      if ($(this).val() === 'recipient') {
        $("#recipient_custom_msg").show();
      } else {
        $("#recipient_custom_msg").hide();
      }
    });

  }

}

module.exports = gift_step1;
