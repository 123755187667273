function Step2() {
  var self = this;

  this.init = function () {

    this.validateApplication();
    this.bindEvents();

    /* Hide the secondary contact block if the user chooses to not fill it in */
    if ($('#secondary_first_name').length) {
      if ($('#secondary_first_name').val()) {
        $('#toggle_secondary_contact').prop('checked', true);
        self.toggle('#secondary_contact_block', $('#toggle_secondary_contact'));
      } else {
        self.toggle('#secondary_contact_block', $('#toggle_secondary_contact'));
      }
    }

    /* Make sure the checkboxes & radio buttons remained checked when going back/forward */
    if ($('#family_events').length) {
      var family_events = $("#family_events").data('value').split(';');
      $.each(family_events, function (index, value) {
        $('input:checkbox[name="family_events"][value="' + value + '"]').prop('checked',true);
      });
    }

    if ($('#interest_area').length) {
      var interest_area = $("#interest_area").data('value').split(';');
      $.each(interest_area, function (index, value) {
        $('input:checkbox[name="interest_area"][value="' + value + '"]').prop('checked',true);
      });
    }

    if ($('#research_area').length) {
      var research_area = $("#research_area").data('value').split(';');
      $.each(research_area, function (index, value) {
        $('input:checkbox[name="research_area"][value="' + value + '"]').prop('checked',true);
      });
    }

    /* Hide the conditional text boxes on page load */
    if ($('form input[type=radio][name=student_type]').length) {
      if ($("#student_type").data('value')) {
        $('input[name="student_type"]').val([$("#student_type").data('value')]);
      }
    }

    /* Make sure the research area other text field toggles correctly on page load */
    if ($('#research_area_other:checked').is(":checked")) {
      $("#other_study").show();
    } else {
      $("#other_study").hide();
    }

  }

  this.validateApplication = function () {

    $("#step2").validate({

      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if (element.attr("type") == "checkbox") {
          error.prependTo(element.parents("div.form-group"));
        } else {
          error.insertBefore(element);
        }
      },

      rules: {
        secondary_first_name: {
          required: true
        },
        secondary_last_name: {
          required: true
        },
        secondary_dob: {
          required: true
        },
        secondary_email: {
          required: true,
          email: true
        },
        secondary_phone: {
          required: true
        }
      },

      messages: {
        secondary_first_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your given name'
        },
        secondary_last_name: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your family name'
        },
        secondary_dob: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your date of birth'
        },
        secondary_email: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your email address',
          email: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter an email address in the correct format, like name@example.com'
        },
        secondary_phone: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your phone number',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Phone must be 8 characters or more',
          pattern: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Phone number must only include digits 0 to 9, hyphens, plus, spaces and brackets'
        },
        num_dependants: {
          maxlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Your dependants need to have a length of 2 or under',
          max: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Your dependants need to have a length of 2 or under',
          min: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Your dependants need to be 0 to 10'
        }
      },

      submitHandler: function(form) {

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#step2').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            jQuery('#step2').prepend('<input type="hidden" name="toStep3" value="1">');
            form.submit();
          });
        });

      }

    });

  }

  this.toggle = function(className, obj) {
    var $input = $(obj);
    if ($input.prop('checked')) $(className).show();
    else $(className).hide();
  }

  this.bindEvents = function () {

    /* Show hide the secondary contact block */
    $("#toggle_secondary_contact").on('click',function() {
      self.toggle('#secondary_contact_block', this);
    });

    /* Show hide the research free text box */
    $("#research_area_other").on('click',function() {
      self.toggle('#other_study', this);
    });

  }

}

module.exports = Step2;
