function CreatePassword () {
  var self = this;

  this.init = function () {

    this.validateApplication();
    this.bindEvents();

  }
  
  this.validateApplication = function () {

    jQuery.validator.addMethod("pwcheck", function(value) {
      //return /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) && /[a-z]/.test(value) && /\d/.test(value) && /[A-Z]/.test(value);
      var specials = /[^A-Za-z 0-9]/g;
      //alert(specials.test(value) && /[a-z]/.test(value) && /\d/.test(value) && /[A-Z]/.test(value));
      //return specials.test(value) && /[a-z]/.test(value) && /\d/.test(value) && /[A-Z]/.test(value);
      return /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g.test(value) && /[a-z]/.test(value) && /\d/.test(value) && /[A-Z]/.test(value);
    });

    $("#createPassword").validate({

      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if (element.attr("type") == "checkbox") {
          error.prependTo(element.parents("div.form-group"));
        } else {
          error.insertBefore(element);
        }
      },

      rules: {
        newpassword: {
          minlength: 8,
          required: true,
          pwcheck: true
        },
        confirmpassword: {
          equalTo: "#newpassword"
        }
      },

      messages: {
        newpassword: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter your password',
          pwcheck: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Passwords must be a minimum of 8 characters, contain upper and lower case letters, a symbol and at least one number.',
          minlength: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Please enter at least 8 characters.'
        },
        confirmpassword: {
          required: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> Enter the confirmed password',
          equalTo: '<i class="fas fa-exclamation-circle" aria-hidden="true"></i><span class="sr-only">Error:</span> The password and confirm passwords need to match'
        }
      },

      submitHandler: function(form) {

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#createPassword').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            jQuery('#createPassword').prepend('<input type="hidden" name="addedPassword" value="1">');
            form.submit();
          });
        });

      }

    });

  }

  this.bindEvents = function () {

    self.togglePasswordVisibility("#show_hide_password");
    self.togglePasswordVisibility("#show_hide_password_confirm");

    const { passwordStrength } = require('check-password-strength');

    $("#newpassword").on('input',function() {
      var strength = passwordStrength($(this).val());
      switch (true) {
        case strength.id === 0:
            $("#colourTooWeak").removeClass("valid");
            $("#colourWeak").removeClass("valid");
            $("#colourStrong").removeClass("valid");
            console.log(strength.value)
            break;
        case strength.id === 1:
            $("#colourTooWeak").addClass("valid");
            $("#colourWeak").removeClass("valid");
            $("#colourStrong").removeClass("valid");
            console.log(strength.value)
            break;
        case strength.id === 2:
            $("#colourTooWeak").addClass("valid");
            $("#colourWeak").addClass("valid");
            $("#colourStrong").removeClass("valid");
            console.log(strength.value)
            break;
        case strength.id === 3:
            $("#colourTooWeak").addClass("valid");
            $("#colourWeak").addClass("valid");
            $("#colourStrong").addClass("valid");
            console.log(strength.value)
            break;
        default:
            console.log(`Invalid password strength`)
      }
      self.matchedElements($("#newpassword"),$("#confirmpassword"),$("#matchedPasswords"));
    });

    $("#confirmpassword").on('input',function() {
      self.matchedElements($("#newpassword"),$("#confirmpassword"),$("#matchedPasswords"));
    });

  }

  this.togglePasswordVisibility = function (selector) {
    $(selector + " a").on('click', function(event) {
      event.preventDefault();
      if ($(selector + ' input').attr("type") == "text") {
        $(selector + ' input').attr('type', 'password');
        $(selector + ' i').addClass( "fa-eye-slash" );
        $(selector + ' i').removeClass( "fa-eye" );
      } else if ($(selector + ' input').attr("type") == "password") {
        $(selector + ' input').attr('type', 'text');
        $(selector + ' i').removeClass( "fa-eye-slash" );
        $(selector + ' i').addClass( "fa-eye" );
      }
    });
  }

  this.matchedElements = function (firstElement,secondElement,matchedDiv) {
    if (firstElement.val()) {
      if (firstElement.val() === secondElement.val()) {
        matchedDiv.html('Matching');
        matchedDiv.addClass("passwordsMatched");
        matchedDiv.removeClass("passwordsDidNotMatch");
        $("#setPassButton").css("display","inline");
      } else {
        matchedDiv.html('Not Matching');
        matchedDiv.addClass("passwordsDidNotMatch");
        matchedDiv.removeClass("passwordsMatched");
        $("#setPassButton").css("display","none");
      }
    }
  }

}

module.exports = CreatePassword;
