function MembershipComplete() {
    this.init = function () {
  
        this.bindEvents();
  
        if ($('#membershipSuccess').length) {

            var tier;

            if ($("#membershipSuccess").data('value')) {
                tier = $("#membershipSuccess").data('value') + ' success';
            } else {
                tier = 'Undefined membership type';
            }

            ga('send', 'event', 'Submit', 'Click',tier);
            
        }

    }
  
    this.bindEvents = function () {
  
    }
  
  }
  
  module.exports = MembershipComplete;
  