function wheelchair_step1() {
  var self = this;

  this.init = function () {

    if ($('#wheelchair__datepicker').length) {
      $('#wheelchair__datepicker').datepicker({
        minDate: 0,
        defaultDate: null,
        dateFormat: 'yy-mm-dd',
        onSelect: function (date) {
          $(".select-time").removeClass("d-none");

          fetch('ajaxActions.cgi?action=bookingTimes&date=' + date)
          .then(response => response.json())
          .then(function(data) {
            
            const timeContainer = document.querySelector(".time-container");
            const elementToRemove = document.getElementById("wheelChairSelects__error");

            // We need to check if we have injected an error message previously. If so remove it or it will confuse the user.
            if (elementToRemove) {
              timeContainer.removeChild(elementToRemove);
            }

            // If the current time has passed the Librarys opening hours we don't want to show the select boxes
            const wheelChairSelects = document.querySelectorAll(".wheelchair__times");
            if (wheelChairSelects) {
              wheelChairSelects.forEach(function (element) {
                element.classList.remove("d-none");
                element.classList.add("d-flex");
              });
            }

            var $bookingTime = $("#wheelchairStartBookingTime");
            $bookingTime.empty();
            // Loop through the JSON data and create options
            if (data.startTimeOptions && Array.isArray(data.startTimeOptions)) {
              data.startTimeOptions.forEach(function(option) {
                var $option = $("<option>");
                $option.val(option.value);
                $option.text(option.text);
                $bookingTime.append($option);
              });
            }

            var $bookingTimeEnd = $("#wheelchairEndBookingTime");
            $bookingTimeEnd.empty();
            // Loop through the JSON data and create options
            if (data.endTimeOptions && Array.isArray(data.endTimeOptions)) {
              data.endTimeOptions.forEach(function(option) {
                var $option = $("<option>");
                $option.val(option.value);
                $option.text(option.text);
                $bookingTimeEnd.append($option);
              });
            }

            if (data.error) {
              wheelChairSelects.forEach(function (element) {
                element.classList.remove("d-flex");
                element.classList.add("d-none");
              });

              const errrorString = document.createElement("p");
              errrorString.textContent = data.error;
              errrorString.id = "wheelChairSelects__error";
              timeContainer.appendChild(errrorString);
            }

          })
          .catch((error) => { console.error('Error:', error);});

        }
      });
    }
    this.validateApplication();
    this.bindEvents();

  }

  this.validateApplication = function () {

    $("#wheelchair_step1").validate({

      onfocusout: false,
      onkeyup: false,
      onclick: false,

      // Make sure error messages relating to radio buttons are placed in the correct location
      errorPlacement: function(error, element) {
        if (element.attr("type") == "checkbox") {
          error.prependTo(element.parents("div.form-group"));
        } else {
          error.insertBefore(element);
        }
      },

      rules: {

      },

      messages: {

      },

      submitHandler: function(form) {

        grecaptcha.ready(function() {
          grecaptcha.execute('6LeP_rkUAAAAAO2GHVS31IwO4Zquu0aA7F0ZSYpc', {action: 'homepage'}).then(function(token) {
            jQuery('#wheelchair_step1').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            form.submit();
          });
        });

      }

    });

  }

  this.bindEvents = function () {

    // JavaScript to handle the button clicks and update the number
    const decrementButton = document.getElementById("decrement");
    const incrementButton = document.getElementById("increment");
    const countElement = document.getElementById("count");
    const countValueInput = document.getElementById("numWheelchairs")
    let count = 1;

    if (incrementButton && decrementButton) {
      // Function to increment the count
      incrementButton.addEventListener("click", () => {
        if (count < 2) {
          count++;
          countElement.textContent = count;
          countValueInput.value = count; // Update the hidden input value
        }
      });

      // Function to decrement the count (with a minimum of 0)
      decrementButton.addEventListener("click", () => {
        if (count > 1) {
          count--;
          countElement.textContent = count;
          countValueInput.value = count; // Update the hidden input value
        }
      });
    }

    // Get references to the select elements
    const startTimeSelect = document.getElementById('wheelchairStartBookingTime');
    const endTimeSelect = document.getElementById('wheelchairEndBookingTime');
    const nextButton = document.getElementById('toStep3');

    if (startTimeSelect && endTimeSelect) {
      startTimeSelect.addEventListener('change', function () {
        const selectedStartTime = startTimeSelect.value;
  
        // Reset the next button
        nextButton.classList.add("d-none");
  
        // Enable all options in the end time select element
        for (let i = 0; i < endTimeSelect.options.length; i++) {
          endTimeSelect.options[i].disabled = false;
        }
  
        // Disable options in the end time select element that are earlier than the selected start time
        for (let i = 0; i < endTimeSelect.options.length; i++) {
          const endTime = endTimeSelect.options[i].value;
          if (endTime <= selectedStartTime) {
            endTimeSelect.options[i].disabled = true;
          }
        }
  
        // Reset the end time select to its default option
        endTimeSelect.selectedIndex = 0;
  
      });
  
      // Shows the next button if the start and end times have been selected
      endTimeSelect.addEventListener('change', function () {
        if (startTimeSelect.value && endTimeSelect.value) {
          nextButton.classList.remove("d-none");
        } else {
          nextButton.classList.add("d-none");
        }
      });
    }
  
  }

}

module.exports = wheelchair_step1;
